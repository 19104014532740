<template>
  <div>
    <el-form
      ref="form"
      class="form"
      :label-width="$l('elevator.labelWidth', '120px')"
      :model="tpNVR"
      :rules="ruleValidate">
      <div class="vm-separate">
        <el-form-item :label="$t('elevator.dtuCode')" prop="dtuCode">
          <el-input
            v-model="tpNVR.dtuCode"
            :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('elevator.nodeCode')" prop="nodeCode">
          <el-input v-model.trim="tpNVR.nodeCode" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('deviceGateway.networkFormat')" prop="networkFormat">
          <el-input
            v-model.trim="tpNVR.networkFormat"
            :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
      </div>
    </el-form>
  </div>

</template>
<script>

  export default {
    // eslint-disable-next-line vue/require-prop-types
    props: ["tpNVR"],
    data() {
      return {
        ruleValidate: {
          dtuCode: [{required: true, message: this.$t("common.tip.notNull"), trigger: "blur"}],
        }
      };
    },
    methods: {
      getData() {
        return this.tpNVR;
      },
    },
  };
</script>
<style type="text/css">

</style>
